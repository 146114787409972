.container {
  text-align: center;
}

.main {
  background: linear-gradient(180deg, #eaf4ff, #fff 90%);
  padding: 3rem;
}

.heading {
  color: #555;
  font-size: 1.7rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.inputGroup {
  width: 50%;
  margin: auto;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #ffff;
}

.input {
  border: none;
  border-radius: 10px;
}

.card {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin: 15px;
  transition: all 0.5s;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  min-width: 250px;
}

.card:hover {
  /* margin-top: 2rem !important; */
  transform: translateY(-10px);
  box-shadow: rgba(153, 153, 153, 0.5) 2px 4px 10px -1px;
}

.cardHeading {
  color: rgb(119, 119, 119);
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.link {
  text-decoration: none;
}

.seeMoreBtn {
  background-color: #ffff;
  color: #444;
  border: 1px solid black;
  margin: auto;
  display: block;
  margin-top: 2rem;
}

@media only screen and (max-width: 576px) {
  .inputGroup {
    width: 100%;
  }
}