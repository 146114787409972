.container {
    position: inherit;
}

.heading {
    color: #555;
    font-size: 1.7rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.subHeading {
    color: #444;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
}

.card {
    border: 1px solid #777;
    border-radius: 10px;
    margin: auto;
    text-align: center;
    width: 250px;
}

.cardHeading {
    color: #444;
    flex-grow: 1;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 30px;
}

.cardPara {
    color: #666;
    flex-grow: 2;
    font-size: 0.9rem;
    min-height: 150px;
    font-weight: 400;
    margin-bottom: 20px;
}

.ctaBtn {
    color: #fff;
    display: block;
    margin: 20px auto;
    padding: 6px 40px;
    font-weight: 700;
    border-radius: 5px;
    background-color: #1E3C70;
    width: 250px;
    text-transform: uppercase;
    font-size: 12px;
}

.ctaBtn:hover {
    color: #fff;
    background-color: #1E3C70;
}


@media only screen and (max-width: 576px) {
    .card {
        margin: 1rem 0;
        width: 100%;

    }

    .row {
        width: 100% !important;
    }
}