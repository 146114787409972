.container {
    position: inherit;
    margin-top: 3rem;
    margin-bottom: 5rem;
}

.heading {
    color: #555;
    font-size: 1.7rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.subHeading {
    color: #444;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 30px;
}

.para {
    color: #666;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
}

.ctaBtn {
    color: #1E3C70;
    margin: 20px 0px;
    padding: 5px 40px;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 5px;
    background-color: #ffff;
    text-transform: uppercase;
    width: 80%;
}

.ctaBtn:hover {
    background-color: #ffff;
    color: #1E3C70;
}

@media only screen and (max-width: 576px) {
    .container img {
        width: 100%;
        height: auto;
    }
    .ctaBtn{
        padding: 5px 23px;
        width: 100%;
    }
}