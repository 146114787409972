.container {
    padding-top: 4rem;
    margin-bottom: 4rem;
    padding-left: 10rem;
    padding-right: 10rem;
    background-color: rgba(244, 246, 248, 0.5);
    position: relative;
    z-index: 1;
}

.heading {
    color: #555;
    font-size: 1.9rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: capitalize;
}

.para1 p {
    color: #666;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;
    width: 80%;
}

.para2 p {
    color: #666;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;
    width: 80%;
}

.ctaBtn {
    color: #fff;
    margin: 20px 0px;
    padding: 6px 40px;
    font-weight: 550 !important;
    border-radius: 5px;
    background-color: #1E3C70;
    /* padding: 6px 16px !important; */
    font-size: 0.75rem;
    min-width: 64px;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    margin-top: 2rem;
}

.sec1Container {
    margin-top: 6rem;
}

.sec2Container {
    text-align: center;
    position: relative;
}

.sec2Float {
    position: absolute;
    width: 450px;
    top: 115px;
    left: 0;
    right: 0;
    margin: auto;
}

.sec2Title {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    color: #233e6f;
}

.sec2Heading {
    margin: 0 auto;
    font-size: 41px;
    max-width: 94%;
    text-align: center;
    font-weight: 700;
    line-height: 53px;
    color: #233e6f;
    width: 400px;
}

.sec2Link {
    display: block;
    font-size: 12px;
    margin-top: -13px;
    text-align: right !important;
    font-weight: 400;
    line-height: 53px;
    margin-right: -10px;
}

.bottomShape {
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 150px;
    /* position: absolute; */
    transform: rotate(2deg) translateY(-50%);
    background-color: rgba(244, 246, 248, 0.5);
    margin-top: -30px;
}

@media only screen and (max-width: 576px) {
    .container {
        padding-top: 1rem;
        margin-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: rgba(244, 246, 248, 0.5);
        position: relative;
        z-index: 1;
    }

    .container p {
        text-align: center;
        width: 100%;
    }

    .ctaBtn {
        display: block;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .img1 {
        width: 100%;
        height: auto;
    }

    .sec2Title {
        font-size: 15px;
    }

    .sec2Heading {
        margin: 0 auto;
        font-size: 30px;
        max-width: 94%;
        text-align: center;
        font-weight: 700;
        line-height: 35px;
        color: #233e6f;
        width: 300px;
    }

    .sec2Link {
        display: block;
        font-size: 12px;
        margin-top: -13px;
        text-align: center !important;
        font-weight: 400;
        line-height: 53px;
        margin-right: -10px;
    }
    .gr2ColImg{
        width: 80px;
        height: 38px;
    }
    .sec2Float{
        width: 100%;
        top: 70px;
    }
    .sec1Container {
        margin-top: 1rem;
    }
}