.heading {
    color: #555;
    font-size: 1.7rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.cardHeading {
    font-size: 1.2rem;
    min-height: 40px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
}

.cardPara {
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
}

.link {
    color: #1e4e8c;
    display: block;
    font-size: 1rem;
    text-align: center;
    font-family: 'Roboto', 'sans-serif';
    font-weight: 400;
    text-decoration: none;
}

.card2Para {
    text-align: center;
    margin-bottom: 50px;
}

/* 3rd sub section */

.heading3{
    text-align: center;
    margin-bottom: 50px;
    margin-top: 3rem;
}



@media only screen and (max-width: 576px) {
    .lastRow img{
       margin: 1rem 0;
    }  
   }