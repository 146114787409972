.container {
    position: relative;
    margin-top: 6rem;
    margin-bottom: 4rem;
}

.heading {
    color: #555;
    font-size: 1.7rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.para {
    color: #444;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 30px;
}

.shopify {
    background-color: rgb(129, 192, 56);
    color: #fff;
    border: 1px solid #999;
    margin: 10px 0px;
    padding: 5px 40px;
    font-weight: 500;
    border-radius: 10px;
    opacity: 0.5;
    font-size: 12px;
    width: 80%;
    display: block;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.shopify:hover {
    background-color: rgb(129, 192, 56);
}

.magento {
    background-color: rgb(243, 123, 32);
    color: #fff;
    border: 1px solid #999;
    margin: 10px 0px;
    padding: 5px 40px;
    font-weight: 500;
    border-radius: 10px;
    font-size: 12px;
    opacity: 0.5;
    width: 80%;
    display: block;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.magento:hover {
    background-color: rgb(243, 123, 32);
}

.woocommarce {
    background-color: rgb(155, 92, 143);
    color: #fff;
    border: 1px solid #999;
    margin: 10px 0px;
    padding: 5px 40px;
    font-weight: 500;
    border-radius: 10px;
    font-size: 12px;
    opacity: 0.5;
    width: 80%;
    display: block;
    text-transform: uppercase;
}

.woocommarce:hover {
    background-color: rgb(155, 92, 143);
}

.plus {
    color: #1E3C70;
    width: 32px;
    height: 32px;
    display: block;
    margin: auto;
}

@media only screen and (max-width: 576px) {
    .shopify, .woocommarce, .magento {
        width: 100%;
    }

    .imgGrid img{
        margin-top: 1rem !important;
        width: 100%;
        height: auto;
    }
}